"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    name: 'Modal',
    props: {
        modalType: {
            type: String,
            required: true,
        },
    },
};

<template>
  <div class="loading-full-screen">
    <div class="block-animation">
      <div class="block-logo">
        <svg
          width="121"
          height="150"
          viewBox="0 0 79 98"
          fill="none"
        >
          <!-- eslint-disable-next-line max-len -->
          <path d="M60.0103 80.6539L41.3777 51.9366C56.1892 49.0145 66.321 38.5043 66.321 26.3973C66.321 12.0116 50.107 0.679688 31.0647 0.679688H0.133789V0.819205V16.4528V65.9271C0.133789 65.9271 2.49734 69.0275 13.5272 72.9029V7.79505V7.4075C16.6865 6.41538 20.1452 5.85731 23.7693 5.85731C38.7778 5.85731 50.95 15.2282 50.95 26.7848C50.95 38.3415 39.1717 47.7124 24.1632 47.7124C24.0844 47.7124 24.0056 47.7124 23.9268 47.7124C23.202 47.7046 22.7529 48.4797 23.139 49.0843L44.6473 81.0414C44.6473 81.0414 53.5263 95.4969 64.7374 97.7059C70.6463 98.8686 78.9187 96.1557 78.9187 96.1557V93.0554C69.4645 94.6055 60.0103 80.6539 60.0103 80.6539Z" fill="#fff"/>
        </svg>
      </div>
      <div class="profile-main-loader">
        <div class="loader">
          <svg
            class="circular-loader"
            viewBox="25 25 50 50"
          >
            <circle
              class="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              :stroke="style.getPropertyValue('--main-color')"
              stroke-width="1.2"
            >
            </circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingFullScreen',
  setup() {
    const style = getComputedStyle(document.body);
    return {
      style,
    };
  },
};
</script>

<style scoped>

</style>

import { createI18n } from 'vue-i18n';
import { merge } from 'lodash';
// import { localesCountries, localesLang } from 'shaload/Countries';
import { localesCountries, localesLang } from '@arkyan/countries';

function parseLocales(locales) {
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = parseLocales(locales);

  // Adding locales from Shaload
  // const countryMessages = parseLocales(countryLocales);
  // Adding the 'Offices' translation

  return merge(messages, localesCountries, localesLang);
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
});

export default i18n;

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "allLanguages", {
  enumerable: true,
  get: function () {
    return _languages.default;
  }
});
Object.defineProperty(exports, "childrenARIPO", {
  enumerable: true,
  get: function () {
    return _childrenARIPO.default;
  }
});
Object.defineProperty(exports, "childrenBX", {
  enumerable: true,
  get: function () {
    return _childrenBX.default;
  }
});
Object.defineProperty(exports, "childrenCN", {
  enumerable: true,
  get: function () {
    return _childrenCN.default;
  }
});
Object.defineProperty(exports, "childrenEAPO", {
  enumerable: true,
  get: function () {
    return _childrenEAPO.default;
  }
});
Object.defineProperty(exports, "childrenEM", {
  enumerable: true,
  get: function () {
    return _childrenEM.default;
  }
});
Object.defineProperty(exports, "childrenOAPI", {
  enumerable: true,
  get: function () {
    return _childrenOAPI.default;
  }
});
Object.defineProperty(exports, "childrenOEB", {
  enumerable: true,
  get: function () {
    return _childrenOEB.default;
  }
});
Object.defineProperty(exports, "childrenPCT", {
  enumerable: true,
  get: function () {
    return _childrenPCT.default;
  }
});
Object.defineProperty(exports, "countries", {
  enumerable: true,
  get: function () {
    return _countriesMin.default;
  }
});
Object.defineProperty(exports, "countriesLang", {
  enumerable: true,
  get: function () {
    return _countriesLangMin.default;
  }
});
exports.epTranslationRoutes = void 0;
Object.defineProperty(exports, "languagesAlpha3to2", {
  enumerable: true,
  get: function () {
    return _alpha3to2Min.default;
  }
});
exports.patentChildren = exports.oebA2TranslationRoutes = exports.localesPatentOffices = exports.localesLang = exports.localesCountries = void 0;
Object.defineProperty(exports, "patentOffices", {
  enumerable: true,
  get: function () {
    return _patentOfficesMin.default;
  }
});
exports.patentOfficesPct = exports.patentOfficesOeb = void 0;
Object.defineProperty(exports, "patentZones", {
  enumerable: true,
  get: function () {
    return _patentZones.default;
  }
});
exports.pctTranslationRoutes = exports.pctA2TranslationRoutes = void 0;
Object.defineProperty(exports, "trademarkOffices", {
  enumerable: true,
  get: function () {
    return _trademarkOfficesMin.default;
  }
});

var _countriesMin = _interopRequireDefault(require("../dataOld/countries.min.json"));

var _countriesLangMin = _interopRequireDefault(require("../dataOld/countriesLang.min.json"));

var _languages = _interopRequireDefault(require("../dataOld/languages.json"));

var _alpha3to2Min = _interopRequireDefault(require("../dataOld/alpha3to2.min.json"));

var _patentZones = _interopRequireDefault(require("../dataOld/patent/patentZones.json"));

var _frCountries = _interopRequireDefault(require("../locales/fr.countries.json"));

var _enCountries = _interopRequireDefault(require("../locales/en.countries.json"));

var _frLanguage = _interopRequireDefault(require("../locales/fr.language.json"));

var _enLanguage = _interopRequireDefault(require("../locales/en.language.json"));

var _patentOfficesMin = _interopRequireDefault(require("../data/patentOffices.min.json"));

var _patentOfficesPctMin = _interopRequireDefault(require("../data/patentOfficesPct.min.json"));

var _patentOfficesOebMin = _interopRequireDefault(require("../data/patentOfficesOeb.min.json"));

var _trademarkOfficesMin = _interopRequireDefault(require("../data/trademarkOffices.min.json"));

var _translationRoutesPctMin = _interopRequireDefault(require("../data/translationRoutesPct.min.json"));

var _translationRoutesOebMin = _interopRequireDefault(require("../data/translationRoutesOeb.min.json"));

var _pctTranslationRoutesA = _interopRequireDefault(require("../data/pctTranslationRoutesA2.json"));

var _oebTranslationRoutesA = _interopRequireDefault(require("../data/oebTranslationRoutesA2.json"));

var _childrenPCT = _interopRequireDefault(require("../data/children/childrenPCT.json"));

var _childrenOEB = _interopRequireDefault(require("../data/children/childrenOEB.json"));

var _childrenEAPO = _interopRequireDefault(require("../data/children/childrenEAPO.json"));

var _childrenARIPO = _interopRequireDefault(require("../data/children/childrenARIPO.json"));

var _childrenOAPI = _interopRequireDefault(require("../data/children/childrenOAPI.json"));

var _childrenGCC = _interopRequireDefault(require("../data/children/childrenGCC.json"));

var _childrenCN = _interopRequireDefault(require("../data/children/childrenCN.json"));

var _childrenBX = _interopRequireDefault(require("../data/children/childrenBX.json"));

var _childrenEM = _interopRequireDefault(require("../data/children/childrenEM.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// Locales
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// New imports
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore

/* eslint-disable import/first, @typescript-eslint/ban-ts-comment */

/* ------------------------------------------------------------------------------------------------------------------ */
// Old part that should be deleted / replaced in time
const localesCountries = {
  fr: _frCountries.default,
  en: _enCountries.default
};
exports.localesCountries = localesCountries;
const localesLang = {
  fr: _frLanguage.default,
  en: _enLanguage.default
};
exports.localesLang = localesLang;
const localesPatentOffices = {
  fr: _frCountries.default,
  en: _enCountries.default
};
/* ------------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------------ */

exports.localesPatentOffices = localesPatentOffices;
const pctTranslationRoutes = _translationRoutesPctMin.default;
exports.pctTranslationRoutes = pctTranslationRoutes;
const epTranslationRoutes = _translationRoutesOebMin.default;
exports.epTranslationRoutes = epTranslationRoutes;
const pctA2TranslationRoutes = _pctTranslationRoutesA.default;
exports.pctA2TranslationRoutes = pctA2TranslationRoutes;
const oebA2TranslationRoutes = _oebTranslationRoutesA.default;
exports.oebA2TranslationRoutes = oebA2TranslationRoutes;
const patentOfficesPct = _patentOfficesPctMin.default;
exports.patentOfficesPct = patentOfficesPct;
const patentOfficesOeb = _patentOfficesOebMin.default; // Children, containing list of countries member of a zone and their link type (for exemple EP zone has children, extensions and validations link type)
// IP and patent zone children
// @ts-ignore

exports.patentOfficesOeb = patentOfficesOeb;
const patentChildren = {
  EP: _childrenOEB.default,
  WO: _childrenPCT.default,
  EA: _childrenEAPO.default,
  OA: _childrenOAPI.default,
  AP: _childrenARIPO.default,
  GC: _childrenGCC.default,
  CN: _childrenCN.default
};
exports.patentChildren = patentChildren;
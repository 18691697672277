import { createStore } from 'vuex';

const store = createStore({
  state: {
    theme: 'darkMode',
    resetData: false,
  },
});

export default store;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postAutoCompleteExaminer = exports.postExaminer = void 0;
const config_1 = require("@/config");
const api_1 = require("@/plugins/api");
function postExaminer(search) {
    return api_1.api.post(config_1.API_URLS.EXAMINER.POST_EXAMINER, { search });
}
exports.postExaminer = postExaminer;
function postAutoCompleteExaminer(search) {
    return api_1.api.post(config_1.API_URLS.EXAMINER.POST_AUTO_COMPLETE_EXAMINER, { search });
}
exports.postAutoCompleteExaminer = postAutoCompleteExaminer;

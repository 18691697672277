"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMetaValue = exports.getConfigValue = void 0;
const getMetaValue = (key) => {
    let value = null;
    const node = document.querySelector(`meta[property="${key}"]`);
    if (node !== null) {
        value = node.getAttribute('content');
    }
    return value;
};
exports.getMetaValue = getMetaValue;
const getConfigValue = (key) => {
    let value = null;
    if (process.env && process.env[`${key}`] !== undefined) {
        value = process.env[`${key}`];
    }
    else {
        return getMetaValue(key);
    }
    return value;
};
exports.getConfigValue = getConfigValue;

<template>
  <SuspenseWithError>
    <template #default>
      <Dashboard></Dashboard>
    </template>
    <template #fallback>
      <LoadingFullScreen></LoadingFullScreen>
    </template>
    <template #error>
      <ScreenError></ScreenError>
    </template>
  </SuspenseWithError>
</template>

<script>
import Dashboard from '@/components/Dashboard.vue';
import SuspenseWithError from '../components/SuspenseWithError.vue';
import LoadingFullScreen from '../components/Loading/LoadingFullScreen.vue';
import ScreenError from '../components/Errors/ScreenError.vue';

export default {
  name: 'Home',
  components: {
    Dashboard,
    SuspenseWithError,
    LoadingFullScreen,
    ScreenError,
  },
};
</script>

<style scoped>

</style>

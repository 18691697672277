"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const chart_js_1 = require("chart.js");
const config_1 = require("@/config");
exports.default = {
    name: 'DoughnutChart',
    props: {
        data: {
            type: Object,
            required: true,
            default: () => ({
                value: null,
                id: null,
                color: [config_1.GraphColors.baseColor, config_1.GraphColors.restColor],
            }),
        },
    },
    setup(props) {
        chart_js_1.Chart.register(...chart_js_1.registerables);
        const ctx = (0, vue_1.ref)(null);
        const graph = (0, vue_1.ref)(null);
        const calculateData = () => {
            const baseValue = props.data.value;
            const restValue = 100 - baseValue;
            return [baseValue.toString(), restValue.toString()];
        };
        const chart = () => {
            const style = getComputedStyle(document.body);
            graph.value = new chart_js_1.Chart(ctx.value, {
                type: 'doughnut',
                data: {
                    datasets: [
                        {
                            data: calculateData(),
                            backgroundColor: [style.getPropertyValue('--sixth-color'), style.getPropertyValue('--seventh-color')],
                            borderColor: style.getPropertyValue('--application-background-color'),
                            borderWidth: 4,
                        },
                    ],
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    hover: {
                        mode: null,
                    },
                    maintainAspectRatio: true,
                },
            });
        };
        (0, vue_1.onMounted)(() => {
            document.getElementById(`doughnut-${props.data.id}`).innerHTML = `<canvas id="${props.data.id}"></canvas>`;
            ctx.value = document.getElementById(props.data.id);
            chart();
            graph.value.ctx.canvas.style.backgroundColor = 'transparent';
        });
        (0, vue_1.onBeforeUnmount)(() => {
            document.getElementById(`doughnut-${props.data.id}`).remove();
        });
        return {};
    },
};

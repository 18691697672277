"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.render = void 0;
const vue_1 = require("vue");
const _hoisted_1 = { class: "legend" };
const _hoisted_2 = { class: "skills" };
const _hoisted_3 = { class: "bars" };
const _hoisted_4 = { class: "skills" };
const _hoisted_5 = ["id"];
const _hoisted_6 = { class: "bar" };
const _hoisted_7 = (0, vue_1.createElementVNode)("span", null, null, -1);
const _hoisted_8 = { class: "legend-bar" };
function render(_ctx, _cache, $props, $setup, $data, $options) {
    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("section", {
        class: (0, vue_1.normalizeClass)(["graph-bar-section", JSON.stringify($props.globalData[$setup.graphBarType.GRAPH_01]) !== JSON.stringify($props.data) ? 'first-tab' : ''])
    }, [
        (0, vue_1.createElementVNode)("div", _hoisted_1, [
            (0, vue_1.createElementVNode)("div", _hoisted_2, [
                (0, vue_1.createElementVNode)("div", null, [
                    (0, vue_1.createElementVNode)("h4", null, (0, vue_1.toDisplayString)($props.data.yourExaminer) + "%", 1)
                ]),
                (0, vue_1.createElementVNode)("div", null, [
                    (0, vue_1.createElementVNode)("h4", null, (0, vue_1.toDisplayString)($props.data.examinerGroupArtUnit) + "%", 1)
                ]),
                (0, vue_1.createElementVNode)("div", null, [
                    (0, vue_1.createElementVNode)("h4", null, (0, vue_1.toDisplayString)($props.data.USPTO) + "%", 1)
                ])
            ])
        ]),
        (0, vue_1.createElementVNode)("div", _hoisted_3, [
            (0, vue_1.createElementVNode)("div", _hoisted_4, [
                ((0, vue_1.openBlock)(true), (0, vue_1.createElementBlock)(vue_1.Fragment, null, (0, vue_1.renderList)($setup.graphSize, (size, index) => {
                    return ((0, vue_1.openBlock)(), (0, vue_1.createElementBlock)("div", {
                        key: size,
                        id: size
                    }, [
                        (0, vue_1.createElementVNode)("div", _hoisted_6, [
                            _hoisted_7,
                            (0, vue_1.createElementVNode)("span", {
                                style: (0, vue_1.normalizeStyle)({ width: `${size}%` })
                            }, null, 4)
                        ]),
                        (0, vue_1.createElementVNode)("h3", _hoisted_8, (0, vue_1.toDisplayString)($setup.GraphBarDataLabel[index]), 1)
                    ], 8, _hoisted_5));
                }), 128))
            ])
        ])
    ], 2));
}
exports.render = render;
